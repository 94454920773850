<template>
  <div>
    <div style="margin-top: 0px; width: calc(100% - 10px); height: 500px">
      <RelationGraph ref="graphRef" :options="graphOptions"> </RelationGraph>
    </div>
  </div>
</template>

<script>
// 如果您没有在main.js文件中使用Vue.use(RelationGraph); 就需要使用下面这一行代码来引入relation-graph
import RelationGraph from "relation-graph";
const graphOptions = {
  debug: false,
  defaultNodeBorderWidth: 0,
  defaultNodeColor: "#2A5CFF",
  allowSwitchLineShape: true,
  allowSwitchJunctionPoint: true,
  defaultLineShape: 1,
  allowShowMiniToolBar: true,
  layouts: [
    {
      label: "中心",
      layoutName: "center",
      centerOffset_x: 0,
      centerOffset_y: 0,
      distance_coefficient: 1,
    },
  ],
  // layout: {
  //   label: "自动布局",
  //   layoutName: "force",
  //   layoutClassName: "seeks-layout-force",
  // },
  defaultJunctionPoint: "border",

  // 这里可以参考"Graph 图谱"中的参数进行设置
};
export default {
  name: "RelationGraphDemo",
  components: { RelationGraph }, // 如果您没有在main.js文件中使用Vue.use(RelationGraph); 就需要在这里注册：components: { RelationGraph }
  props: ["nodes"],
  data() {
    return {
      g_loading: true,
      demoname: "---",
      checked_sex: "",
      checked_isgoodman: "",
      rel_checkList: [
        "师生",
        "上下级",
        "亲戚",
        "情人",
        "朋友",
        "夫妻",
        "勾结",
        "腐化",
        "举报",
      ],
      all_rel_type: [
        "师生",
        "上下级",
        "亲戚",
        "情人",
        "朋友",
        "夫妻",
        "勾结",
        "腐化",
        "举报",
      ],
      graphOptions,
    };
  },
  created() {},
  mounted() {},
  methods: {
    setGraphData({ arr1, arr2 }) {
      const __graph_json_data = {
        rootId: arr1[0].id,
        nodes: arr1,
        lines: arr2,
      };
      this.$refs.graphRef.setJsonData(__graph_json_data, (graphInstance) => {
        // 这些写上当图谱初始化完成后需要执行的代码
      });
    },
    doFilter() {
      const _all_nodes = this.$refs.graphRef.getInstance().getNodes();
      // console.log(_all_nodes, 91);
      const _all_links = this.$refs.graphRef.getInstance().getLinks();
      _all_nodes.forEach((thisNode) => {
        let _isHideThisLine = false;
        thisNode.opacity = _isHideThisLine ? 0.1 : 1;
      });
      _all_links.forEach((thisLink) => {
        // console.log(thisLink, 120);
        thisLink.relations.forEach((thisLine) => {
          if (this.rel_checkList.indexOf(thisLine.data["type"]) === -1) {
            if (!thisLine.isHide) {
              thisLine.isHide = true;
              // console.log("Hide line:", thisLine);
            }
          } else {
            if (thisLine.isHide) {
              thisLine.isHide = false;
              // console.log("Show line:", thisLine);
            }
          }
        });
        // thisNode.opacity = _isShowThisNode ? 1 : 0.1
      });
      this.$refs.graphRef.getInstance().dataUpdated();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.my-node-style {
  background-position: center center;
  background-size: 100%;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  overflow: visible;
}
.c-node-name {
  width: 80px;
  text-align: center;
  color: #2e74b5;
  margin-top: 10px;
}
</style>
