<!-- 居民信息导入弹窗 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="40%"
    :close-on-click-modal="false"
    @close="closeModal"
  >
    <el-upload
      class="upload-demo"
      drag
      :action="`${baseUrl}/residentArchives/importExcel`"
      :headers="headers"
      accept=".xls,.xlsx"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或<em style="color: #556bff">点击上传</em><br />
        <span style="color: #909399; font-size: 12px"
          >系统仅支持上传单个 xls、xlsx 格式文件</span
        >
      </div>
    </el-upload>
    <div>
      <!-- <div>导入模板</div> -->
      <div style="display: flex; align-items: center; margin-top: 2%">
        <i class="el-icon-document" style="font-size: 0.1rem"></i>
        <a style="margin-left: 1%; color: #556bff" @click="down"
          >点击此处 下载居民信息数据导入模板</a
        >
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <ax-button style="margin: 0 2%" @click="closeModal">保存</ax-button>
      <a-button @click="closeModal">取消</a-button>
    </span>
  </el-dialog>
</template>
<script>
import api from "./api";
import { axiosInstance } from "@/common/request";
import cookieUtil from "@/common/cookie-util";
export default {
  components: {},
  data() {
    return {
      api,
      title: "上传文件",
      visible: false,
      exportType: "",
      baseUrl: `${axiosInstance.defaults.baseURL}`,
      // baseUrl: "http://192.168.1.111:1099",
      headers: {
        authorization: cookieUtil.getToken(),
      },
    };
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
    },
    // 取消
    closeModal() {
      this.visible = false;
      this.$emit("refsh");
    },
    down() {
      const values = ''
      const res = api.getTemplate({
        fileName: "居民信息.xlsx",
        params: values,
      });
      console.log(res);
    }
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__body {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
/deep/.el-upload-dragger {
  width: 3.8rem;
  background: #f5f5f5;
}
.upload {
  display: flex;
  flex-direction: column;
}
</style>