<!-- 居民档案详情弹窗页面-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="50%"
    :close-on-click-modal="false"
    @close="closeModal"
  >
    <!-- 基本信息 -->
    <a-descriptions
      style="
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      "
      layout="vertical"
    >
      <a-descriptions-item label="姓名">
        {{ personInforList.residentName ? personInforList.residentName : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="身份证号">
        {{ personInforList.identityCard ? personInforList.identityCard : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="性别">
        {{ personInforList.gender }}
      </a-descriptions-item>
      <a-descriptions-item label="年龄">
        {{ personInforList.age }}
      </a-descriptions-item>
      <a-descriptions-item label="出生日期">
        {{ personInforList.birthDate }}
      </a-descriptions-item>
      <a-descriptions-item label="民族">
        {{ personInforList.nation_dictText }}
      </a-descriptions-item>
      <a-descriptions-item label="文化程度">
        {{
          personInforList.educationalLevel_dictText
            ? personInforList.educationalLevel_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="政治面貌">
        {{
          personInforList.politicalStatus_dictText
            ? personInforList.politicalStatus_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="婚姻状态">
        {{
          personInforList.maritalStatus_dictText
            ? personInforList.maritalStatus_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="职业">
        {{ personInforList.job_dictText ? personInforList.job_dictText : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="兵役状态">
        {{
          personInforList.militaryStatus_dictText
            ? personInforList.militaryStatus_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="宗教信仰">
        {{
          personInforList.religion_dictText
            ? personInforList.religion_dictText
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="籍贯">
        {{ personInforList.nativePlace ? personInforList.nativePlace : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="人员类型" :span="2">
        {{ personInforList.type === "0" ? "本地居民" : "流入居民" }}
      </a-descriptions-item>
      <a-descriptions-item label="照片" :span="3">
        <imagePreview ref="img1" :businessId="businessId"></imagePreview>
      </a-descriptions-item>
    </a-descriptions>
    <!-- 联系信息 -->
    <a-descriptions style="
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      "  layout="vertical" title="联系信息">
      <a-descriptions-item label="联系电话">
        {{ personInforList.phone ? personInforList.phone : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="现住区域">
        {{ personInforList.currentArea ? personInforList.currentArea : "--" }}
      </a-descriptions-item>
      <a-descriptions-item label="详细地址" :span="2">
        {{
          personInforList.cellName
            ? `${personInforList.cellName}/${personInforList.buildingNum}/${personInforList.unitNum}/${personInforList.floorNum}/${personInforList.accountNum}`
            : "--"
        }}
      </a-descriptions-item>
    </a-descriptions>
    <!-- 户籍信息 -->
    <a-descriptions style="
        word-break: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      " layout="vertical" title="户籍信息">
      <a-descriptions-item label="是否户主">
        {{ personInforList.flag === "0" ? "是" : "否" }}
      </a-descriptions-item>
      <a-descriptions-item label="户籍地">
        {{
          personInforList.residencePlace ? personInforList.residencePlace : "--"
        }}
      </a-descriptions-item>
      <!-- <a-descriptions-item label="户主身份证号">
        {{
          personInforList.houseHolderCard
            ? personInforList.houseHolderCard
            : "--"
        }}
      </a-descriptions-item>
      <a-descriptions-item label="与户主关系">
        {{
          personInforList.houseHolderRelation
            ? personInforList.houseHolderRelation === ""
              ? "户主"
              : personInforList.educationalLevel_dictText
            : "户主"
        }}
      </a-descriptions-item> -->
    </a-descriptions>
    <div
      style="
        border-left: 4px solid #2A5CFF;
        font-size: 0.08rem;
        font-weight: bold;
        color: black;
        line-height: 100%;
        margin-bottom: 15px;
      "
    >
      &nbsp;家庭成员
    </div>
    <a-table
      :data-source="personTableData"
      :pagination="false"
      :columns="columns"
      bordered
    >
      <div slot="houseHolderRelation" slot-scope="text, record">
        {{
          record.houseHolderRelation_dictText
            ? record.houseHolderRelation_dictText
            : "户主"
        }}
      </div>
      <span slot="action" slot-scope="record" class="table-operation">
        <a style="color: #e6a23c" @click="watch(record)">详情</a>
      </span>
    </a-table>
    <div
      style="
        border-left: 4px solid #2A5CFF;
        font-size: 0.08rem;
        font-weight: bold;
        color: black;
        line-height: 100%;
        margin: 15px 0;
      "
    >
      &nbsp;人际关系拓扑图
    </div>
    <tuoputu ref="tuoputu" :nodes="nodes" :lines="lines"></tuoputu>
  </el-dialog>
</template>
<script>
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
import tuoputu from "@/pages/views/Acomponents/tuoputu";
export default {
  // props: {
  //   visible: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  components: {
    imagePreview,
    tuoputu,
  },
  data() {
    return {
      api,
      title: "",
      visible: false,
      businessId: "",
      disabled: false,
      flag: "",
      fileIds: [], //图片数组
      personInforList: {},
      nameList: [],
      personTableData: [],
      columns: [
        {
          title: "与户主关系",
          dataIndex: "houseHolderRelation_dictText",
          align: "center",
          width: 160,
          scopedSlots: { customRender: "houseHolderRelation" },
        },
        {
          title: "姓名",
          dataIndex: "residentName",
          align: "center",
          width: 100,
        },
        {
          title: "性别",
          dataIndex: "gender",
          align: "center",
          width: 90,
        },
        {
          title: "年龄",
          dataIndex: "age",
          align: "center",
          width: 90,
        },
        {
          title: "身份证号",
          dataIndex: "identityCard",
          align: "center",
          width: 250,
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ],
      cellName: "",
      buildingNum: "",
      unitNum: "",
      floorNum: "",
      accountNum: "",
      nodes: [],
      lines: [],
    };
  },
  watch: {
    businessId(newval) {
      this.businessId = newval;
    },
  },
  computed: {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
      this.title = record.title;
      this.businessId = record.id;
      this.getPersonInfo(record.id);
      this.getTableData(record);
      this.$nextTick(() => {
        this.$refs.img1.getPicList(record.id);
      });
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.personInforList.age = info.age;
      return info;
    },
    // 取消
    closeModal() {
      this.businessId = "";
      this.visible = false;
      this.$emit("refsh");
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "watch":
          this.visible = true;
          break;
        // 详情
        case "del":
          break;
      }
    },
    watch(record) {
      this.openModal(record);
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
    },
    // 根据id获取详情
    async getPersonInfo(id) {
      const res = await api.getResidentArchivesById(id);
      this.personInforList = res.data;
      this.flag = res.data.flag;
      this.cellName = res.data.cellName;
      this.buildingNum = res.data.buildingNum;
      this.unitNum = res.data.unitNum;
      this.floorNum = res.data.floorNum;
      this.accountNum = res.data.accountNum;
      this.calculateIDCardInfo(res.data.identityCard);
    },
    // 获取居民信息表格数据
    async getTableData(record) {
      (this.lines = []), (this.nodes = []);
      const obj = {
        houseHolderCard: record.houseHolderCard,
      };
      const res = await api.getPersonTableData(obj);
      this.personTableData = res.data;

      this.nodes = res.data.map((item) => {
        return {
          id: item.id,
          text: item.residentName,
        };
      });

      // 总的来说，这段代码的目的是根据数据集中的信息构建节点和边的关系，其中nodes数组存储节点信息，lines数组存储边的信息。这些信息最终可以用于绘制关系图，其中每个节点代表一个人员，每条边代表人员之间的关系。
      res.data.forEach((item) => {
        // 对于数据集中的每个条目，代码遍历数据集，对于每个条目，将其id和houseHolderRelation_dictText添加到this.lines数组中，同时指定线的颜色为'#2D3138'。
        this.lines.push({
          from: item.id,
          text: item.houseHolderRelation_dictText,
          color: "#2D3138",
        });
      });
      // 然后对this.nodes数组中的每个节点，遍历this.lines数组，并为每个节点添加一个新的'to'属性，将这个节点的id赋值给每个线条。
      this.nodes.forEach((item) => {
        this.lines.forEach((it) => {
          it.to = item.id;
        });
      });
      // 最后，代码遍历this.lines数组，如果发现某个线条的起点和终点相同（即自己指向自己），则将这个线条从数组中移除。
      this.lines.forEach((item, index) => {
        if (item.to === item.from) {
          this.lines.splice(index, 1);
        }
      });
      console.log(this.lines, "处理数据");

      this.$nextTick(() => {
        this.$refs.tuoputu.setGraphData({ arr1: this.nodes, arr2: this.lines });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2A5CFF;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
</style>