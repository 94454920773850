<!--居民档案  -->
<template>
  <div class="">
    <a-tabs v-model="activeTab" @change="tabChange">
      <a-tab-pane key="1" tab="本地居民"> </a-tab-pane>
      <a-tab-pane key="2" tab="流入人口" force-render> </a-tab-pane>
    </a-tabs>
    <!-- table模块 -->
    <!-- 根据tab或者menu的切换只改变表格api的写法关键点就是表格的key要和activeTab的值一致 -->
    <ax-table
      :key="activeTab"
      ref="tableBox"
      :columns="columns"
      :searchActions="searchActions"
      :toolActions="toolActions"
      :show-search="true"
      :searchForm="searchForm"
      @bulkDeletion="del"
      @export="expor"
      @exportAll="exportAll"
      @inport="inports"
      @add="add"
      @searchList="searchList"
      @reset="reset"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dynamicSlotNames="['ageRange']"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="flag" slot-scope="{ record }" class="operName">
        <a-button
          v-if="record.flag"
          :class="{ green: record.flag === '0', grey: record.flag === '1' }"
          >{{ record.flag === "0" ? "是" : "否" }}</a-button
        >
        <a-input
          style="
            border: none;
            text-align: center;
            background-color: transparent;
          "
          value="--"
          v-else
        ></a-input>
      </div>
      <div slot="age" slot-scope="{ record }" class="operName">
        {{
          new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4))
        }}
      </div>
      <!-- 联系电话 -->
      <div slot="phone" slot-scope="{ record }" class="operName">
        {{ record.phone ? record.phone : "--" }}
      </div>
      <!-- 文化程度 -->
      <div
        slot="educationalLevel_dictText"
        slot-scope="{ record }"
        class="operName"
      >
        {{
          record.educationalLevel_dictText
            ? record.educationalLevel_dictText
            : "--"
        }}
      </div>
      <!-- 现住区域 -->
      <div slot="currentArea" slot-scope="{ record }" class="operName">
        {{ record.currentArea ? record.currentArea : "--" }}
      </div>
      <!-- 婚姻状态 -->
      <div
        slot="maritalStatus_dictText"
        slot-scope="{ record }"
        class="operName"
      >
        {{
          record.maritalStatus_dictText ? record.maritalStatus_dictText : "--"
        }}
      </div>
      <!-- 职业 -->
      <div slot="job_dictText" slot-scope="{ record }" class="operName">
        {{ record.job_dictText ? record.job_dictText : "--" }}
      </div>
      <!-- 兵役状态 -->
      <div
        slot="militaryStatus_dictText"
        slot-scope="{ record }"
        class="operName"
      >
        {{
          record.militaryStatus_dictText ? record.militaryStatus_dictText : "--"
        }}
      </div>
      <!-- 籍贯 -->
      <div slot="nativePlace" slot-scope="{ record }" class="operName">
        {{ record.nativePlace ? record.nativePlace : "--" }}
      </div>
      <!-- 所在小区 -->
      <div slot="cellName" slot-scope="{ record }" class="operName">
        {{ record.cellName ? record.cellName : "--" }}
      </div>
      <!-- 年龄范围 查询插槽 -->
      <div slot="ageRange" style="display: flex; align-items: center">
        <a-input-number
          placeholder="最小年龄"
          allowClear
          :min="0"
          :max="150"
          :precision="0"
          v-model="ageStart"
        />—<a-input-number
          placeholder="最大年龄"
          allowClear
          :min="0"
          :max="150"
          :precision="0"
          v-model="ageEnd"
        />
      </div>
    </ax-table>
    <!-- 新增居民弹窗 -->
    <add-people ref="personInfo" @refsh="refsh"></add-people>
    <watch-People ref="personInfoDetail" @refsh="refsh"></watch-People>
    <!-- 居民信息导入弹窗 -->
    <inports ref="inports" @refsh="refsh"></inports>
  </div>
</template>

<script>
import AddPeople from "./addPeople.vue";
import WatchPeople from "./watchPeople.vue";
import Inports from "./inports.vue";
// import axTable from "@/components/ax-table";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号/联系电话" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所在小区",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "性别",
    type: "select",
    model: "gender",
    options: {
      options: [
        { label: "男", value: "男" },
        { label: "女", value: "女" },
      ],
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "政治面貌",
    type: "select",
    model: "politicalStatus",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "年龄范围",
    type: "",
    model: "ageRange",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
];

import api from "./api";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { AddPeople, WatchPeople, Inports },
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      activeTab: '1',
      visible: false,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: false,
            width: 120,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 80,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 190,
          },
          {
            title: "年龄",
            dataIndex: "age",
            ellipsis: false,
            width: 70,
          },
          {
            title: "民族",
            dataIndex: "nation_dictText",
            ellipsis: true,
            width: 100,
          },

          {
            title: "文化程度",
            dataIndex: "educationalLevel_dictText",
            ellipsis: false,
            width: 120,
          },
          {
            title: "政治面貌",
            dataIndex: "politicalStatus_dictText",
            ellipsis: true,
            width: 150,
          },

          {
            title: "婚姻状态",
            dataIndex: "maritalStatus_dictText",
            ellipsis: false,
            width: 100,
          },
          {
            title: "职业",
            dataIndex: "job_dictText",
            ellipsis: false,
            width: 120,
          },
          {
            title: "兵役状态",
            dataIndex: "militaryStatus_dictText",
            ellipsis: false,
            width: 100,
          },
          {
            title: "籍贯",
            dataIndex: "nativePlace",
            ellipsis: false,
            width: 250,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: false,
            width: 100,
          },
          {
            title: "所在小区",
            dataIndex: "cellName",
            ellipsis: false,
            width: 100,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: false,
            width: 140,
          },
          {
            title: "现住区域",
            dataIndex: "currentArea",
            ellipsis: false,
            width: 230,
          },
          {
            title: "户主",
            dataIndex: "flag",
            ellipsis: false,
            width: 100,
          },
        ],
        false,
        {
          fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "是否删除当前内容?",
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      searchActions: [
        { name: "searchList", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      toolActions: [
        { name: "add", text: "新增居民" },
        { name: "bulkDeletion", text: "批量删除", type: "#F95A5A" },
        { name: "inport", text: "导入", type: "#67C23A" },
        { name: "export", text: "导出筛选", type: "#67C23A" },
        { name: "exportAll", text: "导出全部", type: "#67C23A" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
      currentAreaCode: "",
      currentArea: "",
      ageStart: "",
      ageEnd: "",
      currentAreaCode1: [],
      cascaderVisible: true, // 控制级联选择器的显示/隐藏
      type: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
  },
  //方法集合
  methods: {
    tabChange(e) {
      console.log(e);
      console.log(this.activeTab);
      if (e == "1") {
        this.dataSourceApis = api.list;
        this.type = "0";
        this.dataSourceParams = { type: "0" };
      } else if (e == "2") {
        this.type = "1";
        this.dataSourceApis = api.list;
        this.dataSourceParams = { type: "1" };
      }
    },
    // 获取所属网格数据（楼栋 单元 楼层 户号）
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "请选择",
                changeOnSelect: true,
              },
            }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 改造的search
    searchList(e) {
      // console.log(e);
      const transformObject = (obj) => {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value[value.length - 1] : value;
          return acc;
        }, {});
      };
      e.formValues = transformObject(e.formValues);
      e.formValues.ageStart = this.ageStart || undefined;
      e.formValues.ageEnd = this.ageEnd || undefined;
      this.$refs.tableBox.getDataSource(e.formValues);
    },
    // 重置的时候级联选择器在页面上的显示未被清空(不知道怎么解决)
    reset(e) {
      // console.log(e, "382");
      this.ageStart = undefined;
      this.ageEnd = undefined;
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
          record.title = "编辑居民信息";
          this.$refs.personInfo.openModal(record);
          break;
        // 详情
        case "watch":
          record.title = "查看居民信息";
          this.$refs.personInfoDetail.openModal(record);
          break;
        // 详情
        case "del":
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteResidentArchivesById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.personInfo.openModal({
        title: "新增居民信息",
        type: this.type,
      });
    },
    // 导入
    inports() {
      this.$refs.inports.openModal();
    },
    // 导出
    expor() {
      this.searchForm.formValues.currentAreaCode = this.currentAreaCode;
      this.searchForm.formValues.ageStart = this.ageStart;
      this.searchForm.formValues.ageEnd = this.ageEnd;
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      console.log(this.searchForm.formValues, "89");
      api.exports({
        fileName: "居民信息.xls",
        params: values,
      });
    },
    // 导出全部
    exportAll() {
      const values = "";
      console.log(this.searchForm.formValues, "89");
      api.exports({
        fileName: "居民信息.xls",
        params: values,
      });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteResidentArchivesByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 政治面貌
      await api.dictData({ dicKind: "political_status" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "politicalStatus",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
      // 所在小区
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.name };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "cellName",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.getDictionsary();
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.green {
  background-color: #f6ffed;
  color: #389e0d;
  border-radius: 4px;
  border: #f6ffed;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>